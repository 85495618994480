import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { RowLink } from '@doltech/ui/lib/figma/Layout/Landing/Footer/RowLink/RowLink';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';

const Main = styled.div`
  padding: 32px 0;
  color: ${colorsV2.gray100};
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;

  .links {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    a {
      color: ${colorsV2.gray100};
      padding: 0 8px;

      :not(:last-child) {
        border-right: 1px solid ${colorsV2.gray100};
      }

      &:hover {
        color: ${colorsV2.primary100};
      }
    }
  }

  ${fromScreen(840)} {
    padding: 32px 0 40px;
    grid-auto-flow: column;
    justify-content: space-between;
  }
`;

interface ContentItem {
  link: string;
  text: string | JSX.Element;
  external: boolean;
  noFollow?: boolean;
  icon?: string;
}

const footerCopyRightLinks: ContentItem[] = [
  {
    text: 'Giới thiệu',
    link: '/gioi-thieu',
    noFollow: false,
    external: true,
  },
  {
    text: 'Chính sách bảo mật',
    link: '/chinh-sach-bao-mat',
    noFollow: false,
    external: true,
  },
  {
    text: 'Điều khoản Sử dụng',
    link: '/dieu-khoan-su-dung',
    noFollow: false,
    external: true,
  },
];

interface GrammarFooterCopyRightProps extends React.HTMLAttributes<HTMLDivElement> {
  align?: 'left' | 'center';
  data?: any[];
}

export const GrammarFooterCopyRight = (props: GrammarFooterCopyRightProps) => {
  const { className, align = 'left', data = footerCopyRightLinks } = props;

  return (
    <Main id="footer-copyright" className={cl('footer-copy-right', className, align)}>
      <div className="title">
        <ResponsiveTypography.Paragraph variant="regular/14-20" color="gray100">
          © {new Date().getFullYear()} DOL Grammar. All rights reserved.
        </ResponsiveTypography.Paragraph>
        <div>
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=36c8b284-aa13-4f7c-96a6-e7e419ec8411"
            title="DMCA.com Protection Status" className="dmca-badge">
            <img
              width="102" height="24"
              src="https://images.dmca.com/Badges/dmca_protected_sml_120i.png?ID=36c8b284-aa13-4f7c-96a6-e7e419ec8411"
              alt="DMCA.com Protection Status"
            />
          </a>
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
        </div>
      </div>

      {data?.length > 0 && (
        <nav className="links">
          <RowLink variant="regular/14" row={footerCopyRightLinks[0]}/>
          <RowLink variant="regular/14" row={footerCopyRightLinks[1]}/>
          <RowLink variant="regular/14" row={footerCopyRightLinks[2]}/>
        </nav>
      )}
    </Main>
  );
};
